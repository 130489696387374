<template>
    <div class="home-box">
      <div>
        <div class="el-dialog-header-box">
          <div class="dialog-title-block"></div>
          <span class="dialog-title-text">商户余额提现记录</span>
        </div>
        <el-divider />
      </div>

      <!-- 查询区域 -->
      <div class="top-query">
        <div class="top-query-left">
          <!-- <div class="demo-input-suffix">
            审核状态：
            <el-select v-model="value1" placeholder="请选择" clearable >
                <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </div> -->
          <div class="demo-input-suffix">
            商家名称：
            <el-select v-model="query.storeid" filterable clearable placeholder="请输入商家名称">
              <el-option
                v-for="item in storeOptions"
                :key="item.id"
                :label="item.storename"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="demo-input-suffix">
            日期：
            <el-date-picker
              v-model="query.datePick"
              align=“center”
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              clearable
              @change="dateChange">
            </el-date-picker>
          </div>
          <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
          <el-button @click="download" type="success" icon="el-icon-printer">导出</el-button>
        </div>
  
        <div class="top-query-right"></div>
      </div>
  
      <!-- table区域-begin -->
      <div class="table-box">
        <el-table :data="tableData.data" stripe style="width: 100%" v-loading="loading">
          <el-table-column prop="storename" label="商家名称" min-width="120">
          </el-table-column>
          <el-table-column prop="tradeTime" label="交易时间" min-width="120">
          </el-table-column>
          <el-table-column prop="tradeSsn" label="交易参考号" min-width="120">
          </el-table-column>
          <el-table-column prop="fasSsn" label="账务参考号" min-width="120">
          </el-table-column>
          <el-table-column prop="txnAmt" label="交易金额" min-width="120">
          </el-table-column>
          <!-- <el-table-column prop="fee" label="手续费" min-width="120">
          </el-table-column> -->
          <!-- <el-table-column prop="tradeType" label="业务类型" min-width="120">
          </el-table-column>
          <el-table-column prop="availableBalance" label="可用金额" min-width="120">
          </el-table-column>
          <el-table-column prop="freezeBalance" label="冻结金额" min-width="120">
          </el-table-column>
          <el-table-column prop="totalBalance" label="账面金额" min-width="120">
          </el-table-column>
          <el-table-column prop="waitingCarryBalance" label="待转结金额" min-width="120">
          </el-table-column> -->
          <el-table-column prop="opponentName" label="对手方名称" min-width="120">
          </el-table-column>
          <el-table-column prop="opponentBankNo" label="对手方卡号" min-width="120">
          </el-table-column>
        </el-table>
      </div>
      <!-- table区域-end -->
  
      <!-- 分页区域-begin -->
      <div>
        <el-pagination
          class="pagination"
          background layout="prev, pager, next"
          :total="tableData.total"
          :page-size="tableData.per_page"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
        </el-pagination>
      </div>
      <!-- 分页区域-end -->
    </div>
  </template>
  
  <script>
  import { mapState, mapMutations } from "vuex";
  
  export default({
  // export default Vue.extend({
    name: "butlerDetail",
    data() {
      return {
        query: {
          storeid: "",
          datePick: '',
          startdate: '',
          enddate: '',
        },
        input1: "",
        tableData: [],
        options1: [{
            value: '选项1',
            label: '待审核'
        }, {
            value: '选项2',
            label: '审核通过'
        }, {
            value: '选项3',
            label: '审核被拒'
        }],
        value1: '',
        storeOptions: [],
        total: 100,
        loading: false,
        page: 1,
      };
    },
    computed: {
      ...mapState(["user", "setting"]),
    },
    mounted() {
      let user = JSON.parse(localStorage.user);
      this.token = user.token;

      this.getStoreOption()
      this.fgjList()
    },
    methods: {
      ...mapMutations(["setuser", "setsetting"]),
      // 日期选择
      dateChange(e) {
        console.log('e', e)
        this.query.startdate = e?e[0]:''
        this.query.enddate = e?e[1]:''
      },
      search() {
        console.log('查询', this.query);
        let data = {
          ...this.query,
          page: this.page,
        }
        this.fgjList(data)
      },
      add() {
        console.log('新增');
      },
      handleDetails() {
        console.log('详情');
      },
      handleEdit() {
        console.log('编辑');
      },
      handleDelete() {
        console.log('删除');
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.page = val
        this.search()
      },
      // 获取提现列表
      fgjList(data) {
        this.loading = true
        this.$api.fgjList({...data}).then(
          res => {
            if(res.code == 'success'){
              this.tableData = res.data
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        })
      },
      // 商家列表
      getStoreOption() {
        this.loading = true
        // 0：有分页，1：无分页数据
        this.$api.getStoreList({type_limit:1}).then(
          res => {
            if(res.code == 'success'){
              this.storeOptions = res.data
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        })
      },
      // 导出
      download() {
        let res = this.tableData.data
        require.ensure([], () => {
        const { export_json_to_excel } = require('../excel/export2Excel.js')
        const tHeader = ['商家名称', '交易时间', '交易参考号', '账务参考号', '交易金额', '对手方名称', '对手方卡号']
        const filterVal = ['storename', 'tradeTime', 'tradeSsn', 'fasSsn', 'txnAmt', 'opponentName', 'opponentBankNo']
        const list = res
        const data = this.formatJson(filterVal, list)
                export_json_to_excel(tHeader, data, '入账明细')
            })
      },
      // 导出
      formatJson(filterVal, jsonData) {
        return jsonData.map(v => filterVal.map(j => v[j]))
      }
    },
  });
  </script>
  
  <style scoped lang="less">
  @import "../assets/less/easyElement.less";
  </style>
  